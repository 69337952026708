import addToMailchimp from 'gatsby-plugin-mailchimp'
import React from "react"
import FbWidget from './fbwidget'

export default class Subscribe extends React.Component {
    // Since `addToMailchimp` returns a promise, you
    // can handle the response in two different ways:

    // Note that you need to send an email & optionally, listFields
    // these values can be pulled from React state, form fields,
    // or wherever.  (Personally, I recommend storing in state).

    state = {
        email: null,
    }

    _handleChange = e => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        })
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault()

        console.log('submit', this.state)

        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`)

                if (result !== 'success') {
                    throw msg
                }
                alert(msg)
            })
            .catch(err => {
                console.log('err', err)
                alert(err)
            })
    }

    render () {
        return (
        <form class="bg-white w-full border-t border-gray-400 px-8 pt-6 pb-8 mb-4" onSubmit={this._handleSubmit}>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                    Schrijf je in op onze nieuwsbrief
                </label>
                <div class="flex items-center">
                    <input class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 mr-3 leading-tight focus:outline-none focus:shadow-outline"
                        type="email"
                        onChange={this._handleChange}
                        placeholder="vul hier je email in"
                        name="email"
                        />
                    <input class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" />
                </div>
                <FbWidget></FbWidget>
            </div>
        </form>
        )
    }
}