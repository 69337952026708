import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Front from "../components/front"
import Subscribe from "../components/subscribe"

const IndexPage = () => (
  <Layout>
    <SEO title="Impact in Dendermonde" />
    <div class="pt-32 md:pt-24">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <p class="uppercase tracking-loose w-full"></p>
          <h1 class="my-4 text-5xl font-bold leading-tight">Hallo! Wij zijn </h1>
          <p class="leading-normal text-4xl text-bold mb-8"><span style={{
            color: '#0097d7'
          }}>JCI Dendermonde</span></p>
          <p class="leading-normal text-2xl mb-8">
            Een club van ondernemende mensen die zich inzet voor de Dendermondse&nbsp;<Link to="/projecten"><a class="text-blue-400 hover:text-blue-500">samenleving</a></Link>, 
              die zichzelf blijft <Link to="/opleidingen"><a class="text-blue-400 hover:text-blue-500">ontwikkelen</a></Link> en die graag andere mensen leert kennen.
          </p>
        </div>
        <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left lg:ml-8 md:ml-8">
          <p class="uppercase tracking-loose w-full"></p>
          <Front></Front>
          <p class="leading-normal text-l mb-8 mt-8">We zijn jongeren tussen de 25 en 40 jaar oud. We zijn behoorlijk divers qua beroep en levensfase. 
            Samen vormen we een gezellige club die van aanpakken weet.</p>
        </div>
        <div class="w-full flex justify-center bg-grey-lighter">
          <iframe src='https://www.youtube.com/embed/4rdowaM7sUM'
                  frameborder='0'
                  allow='autoplay; encrypted-media'
                  allowfullscreen
                  title='video'
                  width='426'
                  height='240'
          />
          
        </div>
        <div class="w-full flex justify-center bg-grey-lighter">
          <iframe src='https://www.youtube.com/embed/wmk7kwE4sUM'
                  frameborder='0'
                  allow='autoplay; encrypted-media'
                  allowfullscreen
                  title='video'
                  width='426'
                  height='240'
          />
          
        </div>
      </div>
      </div>
  </Layout>
)

export default IndexPage
